import React, { Fragment, useEffect } from "react";
import Table from "react-bootstrap/Table";
import {
  capitalizeFirstLetter,
  formatDate,
  MoneyFormatter,
} from "../../common/utils";
import Button from "react-bootstrap/Button";
import useAuth from "../../hooks/useAuth";
import "./TablaInventarioDia.css";


function TablaInventarioDia({
  array
}) {
  const { auth } = useAuth();
  


  return (
    <Table striped bordered hover>
       <thead>
        <tr>
        <th style={{width:"100px"}} >Fecha</th>
          <th>Total de Ventas</th>
          <th>{"Total Vendido"}</th>
        </tr>
      </thead>
      <tbody>
        {array?.map((item, index) => (
          <Fragment key={`item-${index}`}>
            <tr>
            <td style={{justifyItems:"center"}} >{item.fecha} </td>
              <td> {item.TotalVentas} </td>
              <td> {MoneyFormatter(item.totalVendido)} </td>
            </tr>
          </Fragment>
        ))}
      </tbody> 
    </Table>
  );
}

export default TablaInventarioDia;
