import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Header from './components/Header/Header';
import { AuthProvider } from './context/AuthProvider';
import Clients from './pages/Clients/Clients';
import Footer from "./components/Footer/Footer";
import Departamentos from "./pages/Departamentos/Departamentos";
import Reservas from "./pages/Reservas/Reservas";
import Informes from "./pages/Informes/Informes";
import './App.css';
import { CartProvider } from "./context/CartProvider";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CartProvider>
        <Header />
          <Routes>
            <Route path="/clients" element={<Clients />} />
            <Route path="/inventario" element={<Departamentos />} />
            <Route path="/shop" element={<Reservas />} />
            <Route path="/informes" element={<Informes />} />
          </Routes>
          <Footer />
        </CartProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
