import React, { useState, Fragment } from "react";
import useAuth from "../../hooks/useAuth";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "../../api/axios";
import TablaDepartamentos from "../../components/TablaDepartamentos/TablaDepartamentos";
import Spiner from "../../components/Spiner/Spiner";
import ReservarModal from "../../components/Modals/ReservarModal/ReservarModal";
import EditarDepartamentoModal from "../../components/Modals/EditarDepartamentoModal/EditarDepartamentoModal";
import EditarInventarioDepartamentoModal from '../../components/Modals/EditarInventarioDepartamentoModal/EditarInventarioDepartamentoModal'
import Button from "react-bootstrap/Button";
import AñadirDepartamentoModal from "../../components/Modals/AñadirDepartamentoModal/AñadirDepartamentoModal";
const GET_DEPARTAMENTOS_URL = "/depto/";

const Departamentos = () => {
  const { auth } = useAuth();
  const [key, setKey] = useState("inventario");
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  



  React.useEffect(() => {
    if (key === "inventario") {
      async function fetchProduct() {
        const { data } = await axios.get("/api/productos");
        setProduct(data.productos);
        setIsLoading(false);
      }
      fetchProduct();
    }
  }, [key, isLoading, auth]);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {auth ? (
        <Tab eventKey="inventario" title="Inventario">
          {!isLoading ? (
            <TablaDepartamentos array={product}/>
          ) : (
            <Spiner />
          )}
        </Tab>
      ) : null}
      
    </Tabs>
  );
};

export default Departamentos;
