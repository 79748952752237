import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "../../api/axios";
import Productos from "../../components/Productos/Productos";
import { CartShopping } from "../../components/Cart/Cart";
const GET_RESERVAS_URL = "/reservas/";

const Reservas = () => {
  const { auth } = useAuth();
  const [key, setKey] = useState("productos");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

 /*  React.useEffect(() => {
    if (key === "reservas") {
      async function fetchReservas() {
        const token = {
          headers: {
            "Content-Type": "application/json",
            "x-token": auth?.token,
          },
        };
        const response = await axios.get(GET_RESERVAS_URL, token);
        setReservas(response?.data?.reservas);
        setIsLoading(false);
      }
      fetchReservas();
    }
  }, [key, isLoading]); */

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-2"
    >
      <Tab eventKey="productos" title="Productos"  >
        <Productos />
      </Tab>
      <Tab eventKey="cart" title="Carrito" >
        <CartShopping />
      </Tab>
    </Tabs>
  );
};

export default Reservas;
