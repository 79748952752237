import React, { Fragment, useEffect } from "react";
import Table from "react-bootstrap/Table";
import {
  capitalizeFirstLetter,
  formatDate,
  MoneyFormatter,
} from "../../common/utils";
import Button from "react-bootstrap/Button";
import useAuth from "../../hooks/useAuth";
import "./TablaDepartamentos.css";


function TablaDepartamentos({
  array
}) {
  const { auth } = useAuth();

  console.log(array)
  return (
    <Table striped bordered hover>
       <thead>
        <tr>
          <th style={{width:"60px"}} ></th>
          <th>Nombre</th>
          <th>{"Cantiadad (CC)"}</th>
        </tr>
      </thead>
      <tbody>
        {array?.map((item, index) => (
          <Fragment key={`item-${index}`}>
            <tr>
              <td style={{justifyItems:"center"}} ><img src={item.img} alt="item.nombre" width={"50px"} height={"50px"} /></td>
              <td>{capitalizeFirstLetter(item.nombre)}</td>
              <td>{item.cantidadCC}</td>
            </tr>
          </Fragment>
        ))}
      </tbody> 
    </Table>
  );
}

export default TablaDepartamentos;
