import React, { useEffect,useState } from "react";
import Swal from "sweetalert2";

import {useCart} from "../../hooks/useCart";
import {
    capitalizeFirstLetter,
    formatDate,
    MoneyFormatter,
  } from "../../common/utils";

import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";

const CART_URL = "/api/carrito";


export const CartShopping = () => {
  
  let cartLocalStorage = "";
  let cartReaded = localStorage?.getItem('cart');
  if (cartReaded !== undefined && cartReaded !== "undefined") {
    cartLocalStorage = JSON.parse(cartReaded)
  }

  const { cart, setCart } = useCart();
  const { auth } = useAuth();
  //Loading
  const [ isLoading, setIsLoading ] = useState();
  const[precioTotal,setPrecioTotal] = useState(0);
  const currency = function(number){
    return new Intl.NumberFormat('de-DE').format(number);
  };
  //meta title
  useEffect(() => {
    if(cart?.length===0 && cartLocalStorage?.length>0){
      setCart(cartLocalStorage);
    }
  }, [useCart])
  
  useEffect(() => {
    localStorage.setItem('cart',JSON.stringify(cart ?? ""))
  });
  

  function removeCartItem(id) {
    var filtered = cart?.filter(function (item) {
      return item.productId !== id;
    });

    setCart(filtered);
    
   
  }

  function countUP(id, prev_data_attr) {
    setCart(
      cart?.map((p) =>
        p.productId === id ? { ...p, productQuantity: prev_data_attr + 1  } : p
      )
    );
  }

  function countDown(id, prev_data_attr) {
    setCart(
      cart?.map((p) =>
        p.productId === id ? { ...p, productQuantity: prev_data_attr - 1 } : p
      )
    );
  }
  
  useEffect(() => {
      
      const grandTotal = (cart.map (product =>(product.productQuantity * product.productPrice)));
    setPrecioTotal (
        
        grandTotal.reduce((a,b)=>a+b,0)
        )
  } ,[countDown,countUP,removeCartItem])
  
  const handlePago = (e) =>{
    
    e.preventDefault();
    setIsLoading(true);
    const body = {
      producto: cart,
    }
    const config ={
        headers: {
            "Content-Type": "application/json",
            "x-token": auth?.token,
        }
    }
   
    
    axios.post(CART_URL,body,config).then((response)=> {
      setIsLoading(false);
      cart.map((product) => {
          const cantCC={
            cantidadCC: product.cantidadCC-product.productQuantity*500
          }
        axios.put(`/api/productos/${product.productId}`,cantCC,config)
    })
      
      setCart([]);
        localStorage.removeItem('cart');
      Swal.fire(
        "Orden Generada",
        `Su orden se ha generado correctamente sera transferido(a) a su resumen de compra `,
        "success"
      )     
    }
    ).catch((e)=>Swal.fire(
      "Intento Fallido",
      `Notificar al Vendedor: ${e}`,
      "error"
    ) );
    
  };

  //   const grandTotal = (productList.map (product =>(

  //     product.productPrice*product.cartQuantity

  //   )));

  //   const precioTotal = grandTotal.reduce((a,b)=>a+b,0);
  //   const discount = (productList.map (product =>(
  //     product.productPrice*product.cartQuantity-product.productPriceDiscount*product.cartQuantity
  //   )))
  //   const discountTotal = discount.reduce((a,b)=>a+b,0);

  return (
    <div>
      <section id={"cart"}>
        {cart?.length == 0 ? (
            <div class="container" fluid>
                
            <div class="table-responsive">
              <table class="table align-middle mb-0 table-nowrap">
                <thead class="table-light">
                  <tr>
                    <th style={{width: '50%', minWidth: '300px'}}> {"Producto"} </th>
                    <th style={{width: '10%', minWidth: '100px', textAlign: 'right'}}>{"Precio"}</th>
                    <th style={{width: '20%', minWidth: '150px', textAlign: 'right'}}>{"Cantidad"}</th>
                    <th style={{width: '20%', minWidth: '200px', textAlign: 'right'}}>{"Total"}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
                </table>
                    <h2 style={{textAlign:"center", paddingTop:"10px" }}>Carrito Vacio</h2>
            </div>
            </div>
        ):(
          <React.Fragment>
            <div class="page-content">
              <div class="container" fluid>
                
                  <div class="table-responsive">
                    <table class="table align-middle mb-0 table-nowrap">
                      <thead class="table-light">
                        <tr>
                          <th style={{width: '50%', minWidth: '300px'}}> {"Producto"} </th>
                          <th style={{width: '10%', minWidth: '100px', textAlign: 'right'}}>{"Precio"}</th>
                          <th style={{width: '20%', minWidth: '150px', textAlign: 'right'}}>{"Cantidad"}</th>
                          <th style={{width: '20%', minWidth: '200px', textAlign: 'right'}}>{"Total"}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart?.map((product) => (
                          <tr key={product.productId}>
                            <td>
                              <div style={{whiteSpace: 'nowrap', overflowX: 'auto'}}>
                                <div style={{display: 'inline-block'}}>
                                  <img
                                      src={product.productImg}
                                      alt={product.productName}
                                      title={product.productName}
                                      class="avatar-md"
                                  />
                                </div>
                                <div style={{display: 'inline-block'}}>
                                  <h6 class="font-size-12 text-truncate">
                                  &nbsp;&nbsp;&nbsp;
                                  </h6>
                                </div>
                                <div style={{display: 'inline-block'}}>
                                  <h6 class="text-truncate">
                                    {product.productName}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td style={{textAlign: 'right'}}>$ {currency(product.productPrice)}</td>
                            <td style={{textAlign: 'right'}}>
                              <div style={{whiteSpace: 'nowrap', overflowX: 'auto'}}>
                                <div class="portfolio-cart-button" style={{display: 'inline-block'}}>
                                  <button type="button" class="btn" onClick={() => {
                                      countDown(
                                        product.productId,
                                        product.productQuantity
                                      );
                                      // handleDeleteProductUpdate(product);
                                    }}> - 
                                  </button>
                                </div>
                                <div style={{display: 'inline-block', minWidth: '50px', textAlign: 'center'}}>
                                  <h6 class="text-truncate" style={{padding: '0px 0', overflow: "unset"}}>
                                    {product.productQuantity >= 1
                                        ? product.productQuantity
                                        : removeCartItem(product.productId)}
                                  </h6>
                                </div>
                                <div class="portfolio-cart-button" style={{display: 'inline-block'}}>
                                  <button type="button" class="btn" onClick={() => {
                                      countUP(
                                        product.productId,
                                        product.productQuantity
                                      );
                                      // handleAddProductUpdate(product);
                                    }}> +
                                  </button>
                                </div>
                              </div>

                            </td>
                            <td style={{textAlign: 'right'}}>
                              ${" "}
                              {product.productQuantity >= 0
                                ? currency(product.productQuantity * product.productPrice): 0
                              }
                            </td>
                            <td style={{textAlign: 'right'}}>
                              <button class ='buttonTrash' onClick={() => {
                                  removeCartItem(product.productId);
                                }}>
                                <i class="bx bx-trash buttonIconTrash" id="img-carrito"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  
                  <div style={{alignContent: 'revert'}}>
                    <div class="text-sm-end mt-2 mt-sm-0">
                        <h2>Total a Pagar:
                            <span>{MoneyFormatter(precioTotal)}</span>
                        </h2>
                    </div>
                    <div class="text-sm-end mt-2 mt-sm-0">
                      <button href="/checkout" class="cart-btn" onClick={ (e)=> handlePago(e) }>
                        <i class="mdi mdi-cart-arrow-right me-1"/>{" "}
                        {"Pagar"}{" "}
                      </button>
                    </div>
                      
                  </div>
              </div>
            </div>
          </React.Fragment>
        )}
        </section>
      
    </div>
  );
};
