import React, { Fragment } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import useAuth from '../../hooks/useAuth';
import './TablaUsuarios.css';

function TablaUsuarios({ array }) {
  
    const { auth } = useAuth(); 

    

  return (
    <Table striped bordered hover>
      <thead>
        <tr> 
          <th>Nombre</th>
          <th>Correo</th>
          <th>Rol</th>
        </tr>
      </thead>
      <tbody>
          { array?.map((item, index) => (
            <Fragment key={`item-${index}`}>
              <tr>
                <td>{(item.nombre)}</td>
                <td>{(item.correo)}</td>
                <td>{(item.rol)}</td>
              </tr>
            </Fragment>
          ))}
      </tbody>
    </Table>
  );
}

export default TablaUsuarios;