import React, { Fragment, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Popup from "reactjs-popup";
import Spiner from "../Spiner/Spiner";


import {
  capitalizeFirstLetter,
  formatDate,
  MoneyFormatter,
} from "../../common/utils";
import useAuth from "../../hooks/useAuth";
import "./style.css";

import axios from "../../api/axios";
import { useCart } from "../../hooks/useCart";

function Productos({ array }) {
  const { auth } = useAuth();
  const { cart, setCart } = useCart();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [ search, setSearch ] = useState('');

  
  
  const handleSubmit = (e, product) => {
    e.preventDefault();
    const productoSeleccionado = {
      productId: product._id,
      productName: product.nombre,
      productImg: product.img,
      productPrice: product.precio,
      productQuantity: 1,
      cantidadCC : product.cantidadCC,
    };

    const existe = cart?.some(
      (product) => product.productId === productoSeleccionado.productId
    );

    if (existe) {
      const product = cart?.map((product) => {
        if (product.productId === productoSeleccionado.productId) {
          product.productQuantity += 1;
          return product;
        } else {
          return product;
        }
      });

      setCart([...product]);
      localStorage.setItem("cart", JSON.stringify(product ?? ""));
    } else {
      setCart([...(cart ?? []), productoSeleccionado]);
      localStorage.setItem("cart", JSON.stringify(cart ?? ""));
    }
  };
  useEffect(() => {
    async function fetchAPI() {
      const { data } = await axios.get("/api/productos");
      setProducts(data.productos);
      setLoading(false);
    }
    fetchAPI();
  }, [handleSubmit]);

  const filteredProducts = () =>{
    return products.slice(currentPage, currentPage + 4);
  }

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 4);
    }
  };
  const nextPage = () => {
      if (products.filter( product => product.nombre.includes( search ) ).length > currentPage + 4 ) {
        setCurrentPage(currentPage + 4);
      }
         
  };

  return (
    <>
    {!loading ? (
      <>
      
      <div class="portfolio-container">
        {filteredProducts()?.map((d, i) => (
          <div class={`portfolio-item`}>
            <div
              key={`${d.title}-${i}`}
              class={`portfolio-wrap`}
              style={{ alignContent: "center", display: "block" }}
            >
              <div
                className="product-img position-relative"
                style={{ alignContent: "center", display: "block" }}
              >
                {d.featuredArticle ? (
                  <div className="avatar-sm product-ribbon-featured">
                    <div className="avatar-title-featured rounded-circle">
                      <i className="bx bx-star icon-color-featured" />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                {d.productPriceNew < d.productPrice && d.productPriceNew > 0 ? (
                  <div className="avatar-sm product-ribbon-offer">
                    <span className="avatar-title rounded-circle">
                      {` ${(d.productPriceNew * 100) / d.productPrice - 100}%`}
                    </span>
                  </div>
                ) : (
                  <div />
                )}
                <div>
                  <img
                    class="portfolio-item-img"
                    width="300px"
                    height="300px"
                    alt={d.title}
                    src={d.img}
                  />
                </div>
              </div>

              <div class="portfolio-info">
                <h4>{d.nombre}</h4>
                <p dangerouslySetInnerHTML={{ __html: MoneyFormatter(d.precio) }} />
              </div>
              <div class="portfolio-links">
                {auth.token ? (

                  <Popup
                    trigger={
                      <i
                        class="bx bx-info-circle"
                        style={{ cursor: "pointer" }}
                      ></i>
                    }
                    modal
                  >
                    {(close) => (
                      <div class="card" style={{ background: "#eee" }}>
                        <div>
                          <p></p>
                        </div>
                        <div>
                          <p></p>
                        </div>
                        <div
                          class="row"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "300px",
                              height: "300px",
                              position: "relative",
                              overflow: "hidden",
                            }}
                            src={d.img}
                            alt={d.title}
                          />
                        </div>
  
                        <div className="text-container-popup">
                          <h3>{d.nombre}</h3>
                          <h5>
                            <div>
                              <b class="price">{MoneyFormatter(d.precio)}</b>
                            </div>
                          </h5>
                          <p
                            style={{ fontSize: "14px" }}
                            dangerouslySetInnerHTML={{
                              __html: d.descripcion,
                            }}
                          />
                        </div>
                        <div class="row">
                          <div
                            class="col-lg-1"
                            style={{ paddingRight: "0" }}
                          ></div>
                          <div class="col-lg-4" style={{ paddingRight: "0" }}>
                            <button
                              onClick={() => close()}
                              className="portfolio-return-button"
                              style={{
                                width: "100%",
                                border: "none",
                                height: "30px",
                              }}
                            >
                              <i className="bx bx-arrow-back" /> {"Volver"}
                            </button>
                          </div>
                          <div
                            class="col-lg-2"
                            style={{ paddingRight: "0" }}
                          ></div>
                          <div
                            class="col-lg-4"
                            style={{
                              paddingLeft: "0",
                            }}
                          >
                            <button
                              class="portfolio-add-button"
                              style={{
                                width: "100%",
                                border: "none",
                                height: "30px",
                              }}
                              onClick={(e) => {
                                handleSubmit(e, d);
                                close();
                              }}
                            >
                              <i className="bx bx-cart"></i>{" "}
                              {"Agregar al carrito"}
                            </button>
                          </div>
                          <div
                            class="col-lg-1"
                            style={{ paddingRight: "0" }}
                          ></div>
                        </div>
                        <div>
                          <p></p>
                        </div>
                      </div>
                    )}
                  </Popup>
                  
                ):null}
              </div>
            </div>
              {auth.token ? (

              <Popup
                  trigger={
                    <button
                        className="portfolio-add-button"
                        style={{
                          width: "100%",
                          border: "none",
                          height: "30px",
                        }}
                      >
                        {"Ver Información"}
                      </button>
                  }
                  modal
                >
                  {(close) => (
                    <div class="card" style={{ background: "#eee" }}>
                      <div>
                        <p></p>
                      </div>
                      <div>
                        <p></p>
                      </div>
                      <div
                        class="row"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "300px",
                            height: "300px",
                            position: "relative",
                            overflow: "hidden",
                          }}
                          src={d.img}
                          alt={d.title}
                        />
                      </div>

                      <div className="text-container-popup">
                        <h3>{d.nombre}</h3>
                        <h5>
                          <div>
                            <b class="price">{MoneyFormatter(d.precio)}</b>
                          </div>
                        </h5>
                        <p
                          style={{ fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: d.descripcion,
                          }}
                        />
                      </div>
                      <div class="row">
                        <div
                          class="col-lg-1"
                          style={{ paddingRight: "0" }}
                        ></div>
                        <div class="col-lg-4" style={{ paddingRight: "0" }}>
                          <button
                            onClick={() => close()}
                            className="portfolio-return-button"
                            style={{
                              width: "100%",
                              border: "none",
                              height: "30px",
                            }}
                          >
                            <i className="bx bx-arrow-back" /> {"Volver"}
                          </button>
                        </div>
                        <div
                          class="col-lg-2"
                          style={{ paddingRight: "0" }}
                        ></div>
                        <div
                          class="col-lg-4"
                          style={{
                            paddingLeft: "0",
                          }}
                        >
                          <button
                            class="portfolio-add-button"
                            style={{
                              width: "100%",
                              border: "none",
                              height: "30px",
                            }}
                            onClick={(e) => {
                              handleSubmit(e, d);
                              close();
                            }}
                          >
                            <i className="bx bx-cart"></i>{" "}
                            {"Agregar al carrito"}
                          </button>
                        </div>
                        <div
                          class="col-lg-1"
                          style={{ paddingRight: "0" }}
                        ></div>
                      </div>
                      <div>
                        <p></p>
                      </div>
                    </div>
                  )}
                </Popup>
              ):null}
          </div>
        ))}

      </div>
        <div class="row" style={{paddingTop:"100px"}} >
          <div class="col-lg-1" style={{ paddingRight: "0" }}></div>
          <div class="col-lg-4" style={{ paddingRight: "0" }}>
            <button
              onClick={prevPage}
              className="portfolio-return-button"
              style={{
                width: "100%",
                border: "none",
                height: "30px",
              }}
            >
              <i className="bx bx-arrow-back" /> {"Volver"}
            </button>
          </div>
          <div class="col-lg-2" style={{ paddingRight: "0" }}></div>
          <div
            class="col-lg-4"
            style={{
              paddingLeft: "0",
            }}
          >
            <button
              class="portfolio-add-button"
              style={{
                width: "100%",
                border: "none",
                height: "30px",
              }}
              onClick={nextPage}
            >
              {"Siguiente"}
              {" "}
              <i class='bx bx-right-arrow-alt' ></i>
            </button>
          </div>
          <div class="col-lg-1" style={{ paddingRight: "0" }}></div>
        </div>
      
      </>
    )
    : <Spiner/>}
    </>
  );
}

export default Productos;
