import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "../../api/axios";
import Spiner from '../../components/Spiner/Spiner';
import "antd/dist/antd.css";
import { formatDate } from "../../common/utils";
import TablaInventarioMes from "../../components/TablaInventarioMes/TablaInventarioMes";
import TablaInventarioDia from "../../components/TablaInventarioDia/TablaInventarioDia";



const Informes = () => {
  const { auth } = useAuth();
  const [key, setKey] = useState("inventarioMes");
  const [venta, setVenta] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    if (key === "inventarioMes") {
      async function fetchVenta() {
        const { data } = await axios.get("/api/carrito");
        setVenta(data.carrito);
        setIsLoading(false);
      }
      fetchVenta();
    }
  }, [key, isLoading, auth]);
  

  const array = venta.map((item) => {
    return {
      fecha: formatDate(item.fecha),
      TotalVentas: 1,
      totalVendido: item.cart.map((item) => item.productPrice ).reduce((a, b) => a + b, 0),
      
    };
  });
  
 
 // Construcción del nuevo array:
 
 var resultadoMes = [];
 array.reduce(function(res, value) {
 
     // Creamos la posición del array para cada mes
     let mes = new Date(value.fecha).getMonth()+1;
     
     if (!res[mes]) {
        res[mes] = { Mes: mes};
     
        // Inicializamos a 0 el valor de cada key
        Object.keys(value).forEach(function(key) {
            if(key != 'fecha'){
                res[mes][key] = 0;
            }
      })
 
      resultadoMes.push(res[mes])
     }
     
     // Sumamos el valor de cada clave dentro de un bucle
     Object.keys(value).forEach(function(key) {
       if(key != 'fecha'){
           res[mes][key] += value[key];
       }
     })
 
     return res;
 }, {});
 

 var resultadoDia = [];
 array.reduce(function(res, value) {
 
     // Creamos la posición del array para cada mes
     let dia = new Date(value.fecha).getDate();
     let mes = new Date(value.fecha).getMonth()+1;
     
     if (!res[`${dia}/${mes}`]) {
        res[`${dia}/${mes}`] = { fecha: `${dia}/${mes}`};
     
        // Inicializamos a 0 el valor de cada key
        Object.keys(value).forEach(function(key) {
            if(key != 'fecha'){
                res[`${dia}/${mes}`][key] = 0;
            }
      })
 
      resultadoDia.push(res[`${dia}/${mes}`])
     }
     
     // Sumamos el valor de cada clave dentro de un bucle
     Object.keys(value).forEach(function(key) {
       if(key != 'fecha'){
           res[`${dia}/${mes}`][key] += value[key];
       }
     })
 
     return res;
 }, {});
 
 
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="inventarioMes" title="Inventario Mes">
        {!isLoading ?
        
        <TablaInventarioMes array={resultadoMes} />
        :<Spiner />
        }

      </Tab>
      <Tab eventKey="inventarioDia" title="Inventario Dia">
        <TablaInventarioDia array={resultadoDia} />
      </Tab>
      <Tab eventKey="pagos" title="Pagos"></Tab>
    </Tabs>
  );
};

export default Informes;
