import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Login from "../../components/Modals/Login/Login";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import CreateAccount from "../Modals/CreateAccount/CreateAccount";

const NavScrollExample = () => {
  const { auth, setAuth } = useAuth();
  const [modalLogin, setModalLogin] = React.useState(false);
  const [modalCreateAccount, setModalCreateAccount] = React.useState(false);
  const navigate = useNavigate();
  const handleLogOut = () => {
    setAuth(null);
    navigate("/departamentos");
  };
  return (
    <Navbar className="navbar__page" sticky="top" bg="light" expand="lg" >
      <Container fluid>
        <Nav.Link className="brand__link" as={NavLink} to="/">
          <b style={{color:"white"}}> 
            
          SHOP HOME
          </b> 
        </Nav.Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            { auth?.usuario?.rol === "ADMIN_ROLE" ? (
              <Fragment>
                <Nav.Link as={NavLink} to="shop" style={{color:"white"}} >
                  <b>
                    Tienda
                  </b>
                </Nav.Link>
                <Nav.Link as={NavLink} to="clients" style={{color:"white"}}>
                  <b>
                    Usuarios
                  </b>
                </Nav.Link>
                <Nav.Link as={NavLink} to="inventario" style={{color:"white"}}>
                  <b>
                    Inventario
                  </b>
                </Nav.Link>
                <Nav.Link as={NavLink} to="informes" style={{color:"white"}} >
                  <b>
                    Informes
                  </b>
                </Nav.Link>
              </Fragment>
            ) : (
              <Fragment>
                <Nav.Link as={NavLink} to="shop" style={{color:"white"}} >
                  <b>
                    Tienda
                  </b>
                </Nav.Link>
                
              </Fragment>
            )}
          </Nav>
        </Navbar.Collapse>
        {auth?.usuario?.uid ? (
          <Fragment>
            <Navbar.Text className="me-4" style={{color:"white"}}>
              <b>
                {auth ? `BIENVENIDO ${auth?.usuario?.nombre}` : null}
              </b>
            </Navbar.Text>
            <Button variant="primary" style={{background:"rgb(67, 35, 209)"}}  onClick={handleLogOut}>
              Cerrar Sesion
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button
              className="me-2"
              variant="primary"
              onClick={() => setModalLogin(true)}
            >
              Ingresar
            </Button>
            <Button
              variant="secondary"
              onClick={() => setModalCreateAccount(true)}
            >
              Crear Cuenta
            </Button>
          </Fragment>
        )}
        <Login
          show={modalLogin}
          setModalShow={setModalLogin}
          onHide={() => setModalLogin(false)}
        />
        <CreateAccount
          show={modalCreateAccount}
          setModalCreateAccount={setModalCreateAccount}
          onHide={() => setModalCreateAccount(false)}
        />
      </Container>
    </Navbar>
  );
};

export default NavScrollExample;
